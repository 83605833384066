// App.js
import React, { useEffect, useState } from 'react';

const App = () => {
  const [newUrl, setNewUrl] = useState('');

  useEffect(() => {
    let hostname = window.location.hostname;

    // Normalize hostname by removing 'www.' prefix if present
    if (hostname.startsWith('www.')) {
      hostname = hostname.slice(4);
    }

    let title = 'idols.chat';
    let path = '/';

    switch (hostname) {
      case 'shaq.chat':
        title = 'Shaq';
        path = '/shaq';
        break;
      case 'dobrik.chat':
        title = 'David Dobrik';
        path = '/dobrik';
        break;
      case 'mrbeast.chat':
        title = 'MrBeast';
        path = '/mrbeast';
        break;
      case 'loganpaul.chat':
        title = 'Logan Paul';
        path = '/loganpaul';
        break;
      case 'gordonramsay.chat':
        title = 'Gordon Ramsay';
        path = '/gordonramsay';
        break;
      default:
        title = 'idols.chat';
        path = '/';
    }

    document.title = title;

    // Check if the current hostname is not 'idols.chat'
    if (hostname !== 'idols.chat') {
      // Construct the new URL with 'www.' prefix
      const url = `https://www.idols.chat${path}${window.location.search}`;
      setNewUrl(url);
      // Uncomment the line below to enable redirection
      window.location.replace(url);
    }
  }, []);

  return (
    <div>
      {/* {newUrl && (
        <p>
          You are being redirected to: <a href={newUrl}>{newUrl}</a>
        </p>
      )} */}
    </div>
  );
};

export default App;
